import { Order } from '@Types/cart/Order'
import { Cart } from '@Types/cart/Cart'
import { ShippingMethod } from '@Types/cart/ShippingMethod'
import { Product } from '@Types/product/Product'
import { Variant } from "@Types/product/Variant";

const useTrack = () => {
  const purchase = (order: Order) => {
    gtag('event', 'purchase', {
      transaction_id: order.orderId,
      value: order.sum.centAmount / 100,
      tax: (order.taxed?.taxPortions?.[0]?.amount?.centAmount || 0) / 100,
      shipping: 0,
      currency: order.sum.currencyCode,
      coupon: order.custom?.fields?.discount_codes?.[0] || '',
      items: order.lineItems.map((item, index) => {
        return {
          item_id: item.variant.sku,
          item_name: item.name,
          index,
          price: item.price.centAmount / 100,
          quantity: item.count,
        }
      }),
    })
  }

  const viewProduct = (product: Product, variant?: Variant) => {
    variant = variant ?? product.variants.at(0)

    gtag('event', 'view_item', {
      currency: variant?.price?.currencyCode,
      value: variant?.price?.centAmount / 100,
      items: [{
        item_id: variant.sku,
        item_name: product.name,
        item_category: product.categories?.at(0)?.name,
      }]
    })
  }

  const addToCart = (product: Product, variant?: Variant) => {
    variant = variant ?? product.variants.at(0)

    gtag('event', 'add_to_cart', {
      currency: variant?.price?.currencyCode,
      value: variant?.price?.centAmount / 100,
      items: [{
        item_id: variant.sku,
        item_name: product.name,
        item_category: product.categories?.at(0)?.name,
      }]
    })
  }

  const beginCheckout = (cart: Cart) => {
    gtag('event', 'begin_checkout', {
      currency: cart.sum.currencyCode,
      value: cart.sum.centAmount / 100,
      items: cart.lineItems.map((item, index) => {
        return {
          item_id: item.variant.sku,
          item_name: item.name,
          index,
          price: item.price.centAmount / 100,
          quantity: item.count,
        }
      }),
    })
  }

  const addShipping = (cart: Cart, shippingMethod: ShippingMethod) => {
    gtag('event', 'add_shipping_info', {
      currency: cart.sum.currencyCode,
      value: cart.sum.centAmount / 100,
      shipping_tier: shippingMethod.name,
      items: cart.lineItems.map((item, index) => {
        return {
          item_id: item.variant.sku,
          item_name: item.name,
          index,
          price: item.price.centAmount / 100,
          quantity: item.count,
        }
      }),
    })
  }

  const addPayment = (cart: Cart, paymentMethod: string) => {
    gtag('event', 'add_payment_info', {
      currency: cart.sum.currencyCode,
      value: cart.sum.centAmount / 100,
      payment_type: paymentMethod,
      items: cart.lineItems.map((item, index) => {
        return {
          item_id: item.variant.sku,
          item_name: item.name,
          index,
          price: item.price.centAmount / 100,
          quantity: item.count,
        }
      }),
    })
  }

  const addDiscount = (cart: Cart) => {
    gtag('event', 'select_promotion', {
      promotion_id: cart.custom?.fields?.discount_codes?.[0] || '',
      promotion_name: cart.custom?.fields?.discount_codes?.[0] || '',
      coupon: cart.custom?.fields?.discount_codes?.[0] || '',
      items: cart.lineItems.map((item, index) => {
        return {
          item_id: item.variant.sku,
          item_name: item.name,
          index,
          price: item.price.centAmount / 100,
          quantity: item.count,
        }
      }),
    })
  }

  return {
    purchase,
    viewProduct,
    addToCart,
    beginCheckout,
    addShipping,
    addPayment,
    addDiscount,
  }
}

export default useTrack
