import classNames from 'classnames'

type Props = {
  label: string
  className?: string
}

const BadgeNoMedia: React.FC<Props> = ({ label, className }) => {
  return <div className={classNames(className, 'btn btn-badge z-20 bg-white')}>{label}</div>
}

export default BadgeNoMedia
