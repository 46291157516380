import classNames from 'classnames'
import { USP } from 'types/pdp'
import Image from 'frontastic/lib/image'

type Props = {
  className?: string
  allUsp: USP[]
  uspIndexes: string
}
const ProductDetailUSP: React.FC<Props> = ({ className, allUsp, uspIndexes }) => {
  // E.g of this attribute: "1,2" (must start from 1)
  if (!uspIndexes || !allUsp) {
    return null
  }

  const selectedUspIndexes = uspIndexes.split(',')
  const selectedUsp: USP[] = []

  selectedUspIndexes.forEach((value) => {
    if (isNaN(Number(value)) || !allUsp?.[Number(value) - 1]) {
      return
    }

    selectedUsp.push(allUsp[Number(value) - 1])
  })

  if (!selectedUsp.length) {
    return null
  }

  return (
    <div className={classNames('product__USPs', className)}>
      {selectedUsp
        ?.filter((item) => item.boldText)
        .map((usp, index) => (
          <div key={`usp-${index}`} className="usp">
            <div className="relative aspect-1 h-8 w-8">
              <Image media={usp?.icon?.media} alt={usp?.boldText} objectFit="contain" loading={'eager'} sizes="32px" />
            </div>

            <div className="flex flex-col justify-between text-green-dark">
              <b className="text-sm">{usp?.boldText}</b>
              <div className="text-xs">{usp?.normaltext}</div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default ProductDetailUSP
