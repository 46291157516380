/* eslint-disable tailwindcss/classnames-order */
import { useEffect, useMemo, useRef, useState } from 'react'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import Image from 'frontastic/lib/image'
import BadgeNoMedia from 'components/badge-no-media'
import { PRODUCT_SKU } from 'helpers/constants/cart'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import useOutsideClick from 'helpers/hooks/useOutsideClick'
import { ProductOfferProps } from 'types/pdp/product-offer'
import { useAccount, useCart } from 'frontastic'
import { Swiper, SwiperSlide } from 'swiper/react' // eslint-disable-line import/no-unresolved
import useMediaQuery from 'helpers/hooks/useMediaQuery'
// eslint-disable-next-line
import { FreeMode, Pagination, Navigation, Thumbs } from 'swiper/modules'

const TEST_DEVICE_OPTION = 0
const NORMAL_DEVICE_OPTION = 1

const ProductOffer: React.FC<ProductOfferProps> = ({
  tasticData,
  product,
  testDeviceOffer,
  prodBundles,
  onSelectVariant,
}) => {
  const { account, isValidating, loggedIn } = useAccount()
  const { data: cart, isValidating: isCartValidating } = useCart()
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' })
  const [openTooltip, setOpenTooltip] = useState(false)
  const [hoverTooltip, setHoverTooltip] = useState(false)
  const [readMore, setReadMore] = useState(false)
  const tooltipRef = useRef()
  const isWide = useMediaQuery(1024)
  const sliderRef = useRef(null)
  const [slideChange, setSlideChange] = useState(0)
  useOutsideClick(tooltipRef, () => {
    setOpenTooltip(false)
  })

  const toggleReadMore = () => {
    setReadMore(!readMore)
  }

  const slides = [
    { image: '/icons/custom/stopwatch.svg', textId: 'trial.duration', textMessage: 'delivery' },
    { image: '/icons/custom/payment.svg', textId: 'payment', textMessage: 'no.cost' },
    { image: '/icons/custom/usage.svg', textId: 'usage', textMessage: 'unlimited' },
    { image: '/icons/custom/rename.svg', textId: 'return', textMessage: 'free.trial' },
  ]

  const [isTestDeviceOptionActive, setIsTestDeviceOptionActive] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState<number>(TEST_DEVICE_OPTION)

  const Slide = ({ image, textId, textMessage }) => {
    return (
      <div className="lg:min-w-1/2 flex h-full w-full flex-col items-center justify-start gap-2 rounded-2xl bg-[#F0FFF1] bg-[#CEF0D250] p-4  lg:max-w-[260px] lg:bg-transparent lg:p-1">
        <Image
          src={image}
          alt="patient support image 1"
          className="h-[80px] w-[80px] lg:h-[185px] lg:w-[185px]"
          loading="lazy"
        />
        <div
          className=" w-full text-center font-DRL text-[16px] font-bold leading-6 text-green-dark lg:text-left lg:leading-8 xl:text-[24px]"
          dangerouslySetInnerHTML={{
            __html: formatCartMessage({
              id: textId,
            }),
          }}
        />
        <div
          className="text-dark-green w-full text-center text-md"
          dangerouslySetInnerHTML={{ __html: formatCartMessage({ id: textMessage }) }}
        ></div>
      </div>
    )
  }

  useEffect(() => {
    if (isValidating || isCartValidating) {
      return
    }
    const isTestDeviceOfferActive = !loggedIn || (account && account?.isNewCustomer)

    if (cart?.lineItems?.length) {
      const singleNerivio = cart?.lineItems?.find((lineItem) => lineItem?.variant?.sku === PRODUCT_SKU)

      // if single product in cart or user is not new customer, disable test device option
      if (singleNerivio || !isTestDeviceOfferActive) {
        setIsTestDeviceOptionActive(false)
        setSelectedIndex(NORMAL_DEVICE_OPTION)
      } else {
        setIsTestDeviceOptionActive(true)
        setSelectedIndex(TEST_DEVICE_OPTION)
      }
    } else {
      setIsTestDeviceOptionActive(isTestDeviceOfferActive)
      if (!isTestDeviceOfferActive) {
        setSelectedIndex(NORMAL_DEVICE_OPTION)
      }
    }
  }, [isValidating, isCartValidating, cart?.lineItems?.length, account, loggedIn, cart?.lineItems])

  const testDataPrice = useMemo(() => {
    if (testDeviceOffer) {
      // const discountedAmount =
      //   testDeviceOffer?.discount?.amount_off ??
      //   (testDeviceOffer?.discount?.percent_off * product?.price?.centAmount) / 100 ??
      //   0
      const price = product?.price?.centAmount - 0
      const priceInString = CurrencyHelpers.formatForCurrency(price)
      const pricePerUseInString = CurrencyHelpers.formatForCurrency(
        price / ((product?.attributes?.numberOfUsage as number) || 1),
      )
      return {
        priceInString: priceInString,
        pricePerUseInString: pricePerUseInString,
      }
    }
    return null
  }, [testDeviceOffer, product?.price?.centAmount, product?.attributes?.numberOfUsage])

  const originalPricePerUsage = CurrencyHelpers.formatForCurrency(
    product?.price?.centAmount / ((product?.attributes?.numberOfUsage as number) || 1),
  )
  const originalProductPrice = CurrencyHelpers.formatForCurrency(product?.price?.centAmount)

  const handleNext = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      console.log(sliderRef.current.swiper)
      sliderRef.current.swiper.slideNext()
      setSlideChange(sliderRef.current.swiper.activeIndex)
    }
  }

  const handlePrev = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slidePrev()
      setSlideChange(sliderRef.current.swiper.activeIndex)
    }
  }

  const tabPanels = useMemo(() => {
    const tasticContent =
      tasticData?.offerContent?.length && prodBundles?.length && tasticData?.bundleOfferContent?.length
        ? tasticData?.offerContent.concat(tasticData?.bundleOfferContent)
        : tasticData?.offerContent

    const initialOfferContent = tasticContent?.map((offer, index) => ({
      key: `offer-content-${index}`,
      component: (
        <>
          <div className="flex flex-col-reverse items-start gap-4 lg:flex-row lg:items-center lg:justify-between">
            <div className="text-xl font-bold">
              {offer?.isTestDeviceOffer
                ? '45 -Day Nerivio Trial'
                : offer?.testDeviceOfferName || offer?.offerName || product?.name}
            </div>

            <BadgeNoMedia label={formatCartMessage({ id: 'for.first.time.users' })}></BadgeNoMedia>
          </div>
          <div className="my-5 overflow-hidden whitespace-pre-line text-md">
            <span
              dangerouslySetInnerHTML={{
                __html: readMore
                  ? formatCartMessage({ id: 'right.trial' })
                  : formatCartMessage({ id: 'right.trial' }).split(' ').slice(0, 20).join(' '),
              }}
            />
            <div>
              <button className="flex items-center gap-1 font-bold underline" onClick={toggleReadMore}>
                {!readMore && formatCartMessage({ id: 'read.more', defaultMessage: 'Read more' })}
                {readMore && formatCartMessage({ id: 'read.less', defaultMessage: 'Read less' })}
                <Image
                  src="/icons/custom/chevron-up.png"
                  alt="arrow"
                  layout="fill"
                  loading="lazy"
                  className={`h-[24px] w-[24px] ${!readMore ? 'rotate-180' : ''}`}
                />
              </button>
            </div>
          </div>

          {!isWide?.[0] ? (
            <>
              <div className="flex  w-full justify-end gap-2 pb-2">
                <button
                  disabled={slideChange === 0}
                  onClick={handlePrev}
                  className="rounded-3xl bg-[#F0FFF1] py-3 px-5 text-2xl font-bold "
                >
                  {'<'}
                </button>
                <button
                  disabled={slideChange === 3}
                  onClick={handleNext}
                  className="rounded-3xl bg-[#F0FFF1] py-3 px-5 text-2xl font-bold "
                >
                  {'>'}
                </button>
              </div>
              <div className="flex w-full items-stretch justify-center">
                <Swiper
                  ref={sliderRef}
                  scrollbar={false}
                  navigation={false}
                  slidesPerView={1.5}
                  // spaceBetween={20}
                  className="flex items-center justify-center"
                  modules={[FreeMode, Thumbs]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 1.5,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {slides?.map((slide, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <Slide image={slide.image} textId={slide.textId} textMessage={slide.textMessage} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </>
          ) : (
            <div className="grid-cols-2 gap-2 sm:grid md:grid lg:grid">
              {slides?.map((slide, index) => {
                return (
                  <div key={index} className="flex flex-row items-start  justify-start sm:w-full md:w-full">
                    <img src={slide.image} alt="test image" />
                    <div>
                      <div className="text-dark-green text-lg font-bold">{formatCartMessage({ id: slide.textId })}</div>
                      <div
                        className="text-dark-green text-md"
                        dangerouslySetInnerHTML={{ __html: formatCartMessage({ id: slide.textMessage }) }}
                      ></div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </>
      ),
    }))

    return initialOfferContent
  }, [
    tasticData?.offerContent,
    tasticData?.bundleOfferContent,
    prodBundles?.length,
    product?.name,
    selectedIndex,
    isWide,
    slideChange,
  ])

  const handleSelectTab = (index: number) => {
    if (index === TEST_DEVICE_OPTION && !isTestDeviceOptionActive) {
      return
    }

    setSelectedIndex(index)
    if (index === TEST_DEVICE_OPTION || index == NORMAL_DEVICE_OPTION) {
      // Always use master variant
      onSelectVariant(product?.variants?.[0])
    } else {
      onSelectVariant(prodBundles?.[index - 2]?.variants?.[0])
    }
  }

  const onClickTooltip = (event) => {
    event.stopPropagation()
    setOpenTooltip(true)
  }

  const onCloseTooltip = (event) => {
    event.stopPropagation()
    setOpenTooltip(false)
    setHoverTooltip(false)
  }

  const onHoverTooltip = () => {
    setHoverTooltip(true)
  }

  const onLeavingTooltip = () => {
    setHoverTooltip(false)
  }

  const initialOffer = useMemo(() => {
    return tasticData?.offerContent
      ?.filter((item) => !item.isTestDeviceOffer)
      .map((offer, index) => {
        const isTestOffer = offer?.isTestDeviceOffer
        return (
          <Tab
            data-cy={isTestOffer ? `test-device-offer` : `normal-device-offer-${index}`}
            key={`product-offer-${index}`}
            aria-disabled={isTestOffer && !isTestDeviceOptionActive}
            className={classNames('option-button', {
              // disabled: isTestOffer && !isTestDeviceOptionActive,
              'has-out-of-box': offer?.isTestDeviceOffer && tasticData?.cashbackMessage,
              'sm:col-span-3 lg:col-span-1 lg:mb-6 xl:col-span-3': offer?.isTestDeviceOffer,
            })}
          >
            {/* <div className="option-heading">
              {!!offer?.offerTooltip && isTestOffer && !isTestDeviceOptionActive && (
                <div
                  onMouseEnter={onHoverTooltip}
                  onMouseLeave={onLeavingTooltip}
                  className={classNames(
                    'tooltip relative z-[2]',
                    {
                      "show pointer-events-none": openTooltip
                    }
                  )}
                >
                  <div className="tooltip-icon absolute top-0 right-0 bg-right-top" onClick={onClickTooltip}></div>
                  <div
                    className={classNames('tooltip-content top-2 !left-0 !right-0 !w-auto lg:!right-6 lg:!max-w-none', {
                      '!hidden': !openTooltip && !hoverTooltip,
                      '!block': openTooltip || hoverTooltip,
                    })}
                  >
                    <div className="tooltip-close" onClick={onCloseTooltip}></div>
                    <div
                      className="whitespace-pre-line"
                      ref={tooltipRef}
                      dangerouslySetInnerHTML={{ __html: offer?.offerTooltip }}
                    />
                  </div>
                </div>
              )}

              <div className="mb-1 text-sm font-bold lg:text-lg" data-cy="device-name">
                {isTestOffer ? offer?.testDeviceOfferName : product?.name}
              </div>

              <div className="mx-auto flex flex-col gap-2">
                <div data-cy="price-per-usage" className={classNames('text-lg font-bold lg:text-xl')}>
                  {isTestOffer ? testDataPrice?.pricePerUseInString : originalPricePerUsage} /{' '}
                  <div className="text-xs">{tasticData?.perMigraineTreatment}</div>
                </div>

                {isTestOffer && (
                  <div
                    className={classNames('text-lg lg:text-base', {
                      'line-through opacity-50': isTestOffer,
                    })}
                  >
                    {originalPricePerUsage}
                  </div>
                )}
              </div>

              <div className="mx-auto mt-2 flex items-center gap-1">
                <div data-cy="unit-price" className="text-base font-bold">
                  {isTestOffer ? testDataPrice?.priceInString : originalProductPrice}
                </div>
                 {isTestOffer && <div className="text-xs line-through opacity-50">{originalProductPrice}</div>}
              </div>
            </div> */}

            <div className="option-heading">
              <div className="justify-left flex items-center font-DRL">
                <div className="gap-1 text-lg font-bold text-green-dark md:text-2xl">
                  {isTestOffer ? testDataPrice?.pricePerUseInString : originalPricePerUsage}{' '}
                </div>
                <div className="mx-2 text-md text-green-dark md:text-lg">
                  {formatCartMessage({ id: 'per.treatment' })}{' '}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p>
                  {product?.attributes?.numberOfUsage}
                  {'  '}
                  {formatCartMessage({ id: 'total.treatments', defaultMessage: 'treatments in Total' })}
                </p>
                <p>
                  {originalProductPrice} {formatCartMessage({ id: 'total.price' })}
                </p>
              </div>
              <div className="flex justify-between rounded-2xl bg-gradiant-product p-3">
                <div>
                  {formatCartMessage({ id: 'trial.available', defaultMessage: '45 Days Nerivio Trial Available' })}
                </div>
                <a href="#options" className="font-bold underline">
                  {formatCartMessage({ id: 'read.more', defaultMessage: 'Know more' })}
                </a>
              </div>
            </div>
          </Tab>
        )
      })
  }, [
    tasticData?.offerContent,
    tasticData?.cashbackMessage,
    tasticData?.perMigraineTreatment,
    isTestDeviceOptionActive,
    openTooltip,
    hoverTooltip,
    product?.name,
    testDataPrice?.pricePerUseInString,
    testDataPrice?.priceInString,
    originalPricePerUsage,
    originalProductPrice,
  ])

  // const singleNerivio = cart?.lineItems?.find((lineItem) => lineItem?.variant?.sku === PRODUCT_SKU)

  // const prodBundleOffer = useMemo(
  //   () =>
  //     prodBundles?.map((product, index) => (
  //       <Tab
  //         disabled={singleNerivio ? true : false}
  //         data-cy={`bundle-offer-${index}`}
  //         key={`bundle-offer-${index}`}
  //         className={classNames('option-button')}
  //       >
  //         <div className="option-heading-bundle">
  //           <div className="mb-1 text-sm font-bold lg:text-lg" data-cy="device-name">
  //             {!!product?.attributes?.pdpName
  //               ? product?.attributes?.pdpName
  //               : product?.attributes?.prefixProductName
  //               ? product?.name?.replace(product?.attributes?.prefixProductName, '').trim()
  //               : product?.name}
  //           </div>
  //           <div className="mx-auto flex items-center gap-2">
  //             {!!product?.attributes?.numberOfUsage && (
  //               <div data-cy="price-per-usage" className={classNames('text-lg font-bold lg:text-xl')}>
  //                 {CurrencyHelpers.formatForCurrency(product?.price?.centAmount / product?.attributes?.numberOfUsage)}
  //               </div>
  //             )}
  //           </div>

  //           <div className="text-xs">{tasticData?.perMigraineTreatment}</div>

  //           <div className="mx-auto mt-2 flex items-center gap-1">
  //             <div data-cy="unit-price" className="text-base font-bold">
  //               {CurrencyHelpers.formatForCurrency(product?.price?.centAmount)}
  //             </div>
  //             {!!product?.price?.custom?.recommended?.centAmount && (
  //               <div className="text-xs line-through opacity-50">
  //                 {CurrencyHelpers.formatForCurrency(product?.price?.custom?.recommended?.centAmount)}
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </Tab>
  //     )),
  //   [prodBundles, tasticData?.perMigraineTreatment, singleNerivio],
  // )

  return (
    <div className="product__options">
      <Tab.Group selectedIndex={selectedIndex} onChange={handleSelectTab}>
        <div
          className={classNames('option-list', {
            'without-bundles': !tasticData?.isBundlesOffer,
          })}
        >
          <Tab.List className="option-button-wrapper">
            {initialOffer}

            {/* {tasticData?.isBundlesOffer && prodBundleOffer} */}
          </Tab.List>
        </div>

        {/* product__desc */}
        <div id="options" className="product__desc mb-8" dangerouslySetInnerHTML={{ __html: product?.description }} />

        {/* offer__desc */}
        {/* <div className="product__desc mt-2 text-secondary-dark">{tasticData?.offerContent[0].description}</div> */}

        <hr className="my-5 border-secondary-light" />

        {/* product__desc */}

        <Tab.Panels className="option-detail">
          {tabPanels?.map((panel) => (
            <Tab.Panel key={panel.key}>{panel.component}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default ProductOffer
