import React from 'react'
import InProgress from 'components/in-progress'


const InProgressTastic: React.FC<any> = () => {
  return (
    <InProgress />
  )
}

export default InProgressTastic
