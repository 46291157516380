import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Money } from '@Types/product/Money'
import { Variant } from '@Types/product/Variant'
import { Voucherify } from '@Types/voucherify/Voucher'
import toast from 'react-hot-toast'
import Loader from 'components/commercetools-ui/loader'
import { ACCOUNT_DELETION } from 'helpers/constants/localStorage'
import { CHECKOUT } from 'helpers/constants/statisURLs'
import { useFormat } from 'helpers/hooks/useFormat'
import { getReferenceTarget, getTargetProps } from 'helpers/reference'
import { ProductDetailsTasticProps } from 'types/pdp'
import Image from 'frontastic/lib/image'
import useTrack from '../../../../helpers/hooks/useTrack'
import ProductImageGallery from './gallery'
import ProductOffer from './offer'
import ProductDetailUSP from './usp'
import { useCart } from 'frontastic'

export interface Props {
  tasticData: ProductDetailsTasticProps
  product: UIProduct
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>
  onAddToWishlist: () => void
  variant: Variant
  onChangeVariantIdx: (idx: number) => void
  quickBuyEnabled?: boolean
  testDeviceOffer?: Voucherify
  prodBundles?: UIProduct[]
}

export type UIProduct = {
  productId: string
  name: string
  variants: Variant[]
  price?: Money & {
    custom?: {
      recommended?: Money
    }
  }
  images?: UIImage[]
  colors?: UIColor[]
  sizes?: UISize[]
  description: string
  details?: UIDetail[]
  isOnWishlist?: boolean
  _url: string
  attributes?: {
    [key: string]: unknown
    numberOfUsage?: number
    prefixProductName?: string
  }
}
interface UIImage {
  id?: string
  src?: string
  alt?: string
}
export interface UIColor {
  name?: string
  key?: string
  bgColor?: string
  selectedColor?: string
}
export interface UISize {
  label: string
  key: string
}
interface UIDetail {
  name: string
  items: string[]
}

export default function ProductDetail({
  tasticData,
  product,
  onAddToCart,
  onAddToWishlist,
  variant,
  onChangeVariantIdx,
  quickBuyEnabled,
  testDeviceOffer,
  prodBundles,
}: Props) {
  //next/router
  const router = useRouter()
  const { data: cart, setCustomProductType, isValidating } = useCart()
  const { addToCart, viewProduct } = useTrack()

  //i18n messages
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' })
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' })

  //Component states
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedVariant, setSelectedVariant] = useState<Variant>(variant)

  useEffect(() => {
    viewProduct(product, variant)
    if (cart?.cartId) setCustomProductType()
    return () => {}
  }, [])

  const handleAddToCart = async () => {
    setLoading(true)
    addToCart(product, selectedVariant)
    await onAddToCart(selectedVariant, 1).then(() => {
      setCustomProductType()
    })
    router.push(CHECKOUT)
  }

  const onSelectVariant = (variant: Variant) => {
    setSelectedVariant(variant)
  }

  const isPrescriptionRequired =
    !!getReferenceTarget(tasticData?.prescriptionRef) && product?.attributes?.isPrescriptionRequired

  /**
   * TODO: remove this toast when we get the homepage back
   */
  const showToastForAccountDeletion = () => {
    toast.dismiss()
    toast.success(
      <>
        <p>{formatAccountMessage({ id: 'account.delete.request.success' })}</p>
        &nbsp;
        <button onClick={() => toast.dismiss()}>&#x2715;</button>
      </>,
      { position: 'top-right', duration: 30000, style: { marginTop: 'var(--toast-margin-top)' } },
    )
  }

  if (typeof window !== 'undefined' && window.localStorage.getItem(ACCOUNT_DELETION) === '1') {
    showToastForAccountDeletion()
    window.localStorage.removeItem(ACCOUNT_DELETION)
  }

  return (
    <>
      {loading && <Loader />}
      <div className="PDP bg-gradiant-product">
        <div className="PDP__container">
          <div className="PDP--left">
            {/* product__gallery */}
            <ProductImageGallery
              images={product?.images}
              tasticData={tasticData}
              isPrescriptionRequired={!!isPrescriptionRequired}
            />

            {!!tasticData?.imageDisclaimer && (
              <p className="mt-4 text-center text-xs text-gray-medium">{tasticData?.imageDisclaimer}</p>
            )}

            {/* product__usp--desktop */}
            {product?.attributes?.isUSPAvailable && !!tasticData?.usp?.length && (
              <ProductDetailUSP
                allUsp={tasticData?.usp}
                uspIndexes={product?.attributes?.uspList as string}
                className="product__USPs--desktop"
              />
            )}
          </div>
          <div className="PDP--right rounded-xl p-10 pb-0">
            <h1 className="product__name mb-5">{product?.name}</h1>

            <ProductOffer
              tasticData={tasticData}
              product={product}
              testDeviceOffer={testDeviceOffer}
              prodBundles={prodBundles}
              onSelectVariant={onSelectVariant}
            />

            {/* product__CTA */}

            <button
              data-cy="buy-button"
              className="product__CTA btn btn-primary align-stretch mb-[-15px] w-full pt-2"
              onClick={handleAddToCart}
              disabled={!isValidating && cart?.lineItems?.length && cart?.custom?.fields?.productType === 'FREETRIAL'}
            >
              {formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to bag' })}
            </button>

            {/* product__usp--mobile */}
            {product?.attributes?.isUSPAvailable && !!tasticData?.usp?.length && (
              <ProductDetailUSP
                allUsp={tasticData?.usp}
                uspIndexes={product?.attributes?.uspList as string}
                className="product__USPs--mobile"
              />
            )}

            {/* product__prescription */}
            {!!isPrescriptionRequired && (
              <div className="flex flex-row sm:pt-10 lg:flex-row lg:justify-center">
                <Link href={getReferenceTarget(tasticData?.prescriptionRef)}>
                  <a
                    {...getTargetProps(tasticData?.prescriptionRef)}
                    className="flex flex-row gap-2 text-green-dark underline sm:text-md lg:text-md"
                    data-cy="prescription-link"
                  >
                    {tasticData?.prescriptionQuestion}
                    <Image src="/images/arrow-right-forward.png" sizes="24px" loading="eager" alt="arrow right" />
                  </a>
                </Link>
                <div />
              </div>
            )}

            <div className="product__footnote">
              <div className="text-sm text-gray-text">{product.variants[0].attributes.footNote}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
